import styled from "styled-components"

export const GlobalStyles = styled.div`
  /* Kodiak Color Classes */
  .kodiak-green {
    color: #4b5320;
  }

  .kodiak-orange {
    color: #f7931e;
  }

  .kodiak-black {
    color: #333333;
  }

  font-family: sans-serif;

  .mobile-nav-link {
    color: black;
    text-decoration: none;
  }
`
