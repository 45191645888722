import React from "react"
import { FooterStyles } from "./styles/FooterStyles"
import BearImage from "../../images/kodiak-bear-mark_bear-solo.png"
import { Link } from "gatsby"

const Footer = () => {
  return (
    <div>
      <FooterStyles>
        <div id="footer-container">
          <div className="footer-elm">
            <h2>Products</h2>
            <ul className="product-section">
              <div className="fist-three">
                <Link
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                  to="/floor-series-page"
                >
                  <li style={{ marginRight: "20px" }}>Floor Machines</li>
                </Link>
                {/* <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/k10-compact"
                >
                  <li>K10 Compact</li>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/k10-standard"
                >
                  <li>K10 Standard</li>
                </Link> */}
              </div>
              <div className="second-three">
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/k12-series"
                >
                  <li>K12 Series</li>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/k16-series"
                >
                  <li>K16 Series</li>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/k19-series"
                >
                  <li>K19 Series</li>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/k25-series"
                >
                  <li>K25 Series</li>
                </Link>
                {/* <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/rider-series"
                >
                  <li>Rider Series</li>
                </Link> */}
              </div>
            </ul>
          </div>
          <div className="footer-elm">
            <Link
              to="/support"
              style={{ textDecoration: "none", color: "black" }}
            >
              <h2>Support</h2>
              <ul>
                <li>Tech-Specs</li>
                <li>Operators Manuals</li>
                <li>Warranty</li>
                <li>Register a Machine</li>
              </ul>
            </Link>
          </div>
          <div className="footer-elm">
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/about"
            >
              <h2>About</h2>
            </Link>
            <ul>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/contact"
              >
                <li>Contact</li>
              </Link>
            </ul>
          </div>
          <div className="footer-elm">
            <h2>Social</h2>
            <ul className="social-links">
              <a
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                href="https://www.facebook.com/Kodiak-Cleaning-Equipment"
                target="_blank"
              >
                <i
                  style={{ marginRight: "10px" }}
                  className="fab fa-facebook"
                ></i>
                <li>Facebook</li>
              </a>
              <a
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                href="https://www.instagram.com/kodiak.equip/"
                target="_blank"
              >
                <i
                  style={{ marginRight: "10px" }}
                  className="fab fa-instagram"
                ></i>
                <li>Instagram</li>
              </a>
              <a
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                href="https://www.linkedin.com/company/kodiak-cleaning-equipment"
                target="_blank"
              >
                <i
                  style={{ marginRight: "10px" }}
                  className="fab fa-linkedin"
                ></i>
                <li>LinkedIn</li>
              </a>
              <a
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                href="https://twitter.com/KodiakEquipment"
                target="_blank"
              >
                <i
                  style={{ marginRight: "10px" }}
                  className="fab fa-twitter-square"
                ></i>
                <li>Twitter</li>
              </a>
              <a
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                href="https://www.youtube.com/channel/UCoD5pJvZmB6RZ2B-KSwmqyA"
                target="_blank"
              >
                <i
                  style={{ marginRight: "10px" }}
                  className="fab fa-youtube-square"
                ></i>
                <li>You Tube</li>
              </a>
            </ul>
          </div>
          <div id="bear-image">
            <img style={{ width: "310px" }} src={BearImage} alt="" />
          </div>
        </div>

        <div id="mobile-footer">
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to="/products"
          >
            <p>Products</p>
          </Link>
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to="/support"
          >
            <p>Support</p>
          </Link>
          <Link style={{ textDecoration: "none", color: "black" }} to="/about">
            <p>About</p>
          </Link>
          <div className="mobile-social">
            <a
              href="https://www.facebook.com/Kodiak-Cleaning-Equipment"
              target="_blank"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/kodiak.equip/" target="_blank">
              <i className="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/kodiak-cleaning-equipment"
              target="_blank"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a href="https://twitter.com/KodiakEquipment" target="_blank">
              <i className="fab fa-twitter-square"></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UCoD5pJvZmB6RZ2B-KSwmqyA"
              target="_blank"
            >
              <i className="fab fa-youtube-square"></i>
            </a>
          </div>
        </div>

        <div id="footer-copy">&copy; 2019 R.P.S. Corporation</div>
      </FooterStyles>
    </div>
  )
}

export default Footer
