import React, { Component } from "react"
import styled from "styled-components"
import Nav from "../navigaion/Navigation"

const HeaderStyles = styled.div`
  position: relative;
  background-color: #424242;
  width: 100%;
  height: 70px;
  z-index: 10;
`

class Header extends Component {
  render() {
    return (
      <HeaderStyles>
        <Nav />
      </HeaderStyles>
    )
  }
}

export default Header
