import React, { Component } from "react"
import { NavStyes } from "./styles/NavStyles"

import KodiakLogo from "../../images/Asset 3kodiak-import-no-black-bg.png"

import MobileNav from "./MobileNav"
import { Link } from "gatsby"

class Navigation extends Component {
  state = {
    productMenuVisible: true,
    animateInOut: "0",
    transitionTime: "0.5s",

    mobileMenuVisible: false,
    mobileInOut: "-10000px",
    mobileTransition: "0.5s",
    mobileZIndex: "0",
    mobileDisplay: "none",
  }
  toggleProductMenu = () => {
    if (!this.state.productMenuVisible) {
      this.setState({ productMenuVisible: true, animateInOut: "0" })
    } else if (this.state.productMenuVisible) {
      this.setState({ productMenuVisible: false, animateInOut: "-1000px" })
    }
  }

  toggleMobileMenu = () => {
    if (
      !this.state.mobileMenuVisible &&
      this.state.mobileInOut === "-10000px"
    ) {
      this.setState({
        mobileDisplay: "block",
      })
      setTimeout(() => {
        this.setState({
          mobileMenuVisible: true,
          mobileInOut: "0",
        })
      }, 50)
    } else if (this.state.mobileMenuVisible) {
      this.setState({
        mobileMenuVisible: false,
        mobileInOut: "-10000px",
      })
      setTimeout(() => {
        this.setState({ mobileDisplay: "none" })
      }, 70)
    }
  }
  render() {
    return (
      <NavStyes>
        <nav id="nav-container">
          <Link to="/">
            <div id="company-logo">
              <img style={{ width: "300px" }} src={KodiakLogo} alt="" />
            </div>
          </Link>
          {/* <div id="blank-responsive-div"></div> */}
          <div id="nav-link-container">
            <Link className="nav-links" to="/">
              <div className="nav-links">Home</div>
            </Link>
            <Link className="nav-links" to="/products">
              <div className="nav-links">Products</div>
            </Link>
            <Link className="nav-links" to="/videos">
              <div className="nav-links">Videos</div>
            </Link>
            <Link className="nav-links" to="/images">
              <div className="nav-links">Images</div>
            </Link>
            <Link className="nav-links" to="/support">
              <p className="nav-links">Support</p>
            </Link>
            <Link to="/about" className="nav-links">
              <p className="nav-links">About</p>
            </Link>
          </div>
          <div id="nav-buttons">
            <Link className="nav-links" to="/contact">
              <button type="button" id="contact-btn">
                CONTACT US
              </button>
            </Link>
            <Link className="nav-links" to="/demo">
              <button type="button" id="demo-btn">
                SCHEDULE A DEMO
              </button>
            </Link>
          </div>
          <div id="login">
            <a
              style={{ textDecoration: "none", color: "lightgray" }}
              href="http://portal.rpscorporation.com"
              target="_blank"
            >
              Login
            </a>
          </div>
          <div id="phone-number">
            <a
              style={{ color: "white", textDecoration: "none" }}
              href="tel:855-965-0011"
            >
              <p>
                <i className="fas fa-phone"></i> 1-855-965-0011
              </p>
            </a>
          </div>
          <div id="burger-menu">
            <i onClick={this.toggleMobileMenu} className="fas fa-bars"></i>
          </div>
        </nav>

        <div
          id="mobile-nav"
          style={{
            marginLeft: this.state.mobileInOut,
            transitionDuration: "0.5s",
            zIndex: this.state.mobileZIndex,
            display: this.state.mobileDisplay,
          }}
        >
          <MobileNav />
        </div>
      </NavStyes>
    )
  }
}

export default Navigation
