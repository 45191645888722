import React, { Component } from "react"
import { MobileNavStyles } from "./styles/MobileNavStyles"
import { Link } from "gatsby"

class MobileNav extends Component {
  render() {
    return (
      <div>
        <MobileNavStyles>
          <div style={{ zIndex: "11" }}>
            <ul>
              <Link className="mobile-nav-link" to="/">
                <li>Home</li>
              </Link>
              <Link className="mobile-nav-link" to="/products">
                <li>Products</li>
              </Link>
              <Link className="mobile-nav-link" to="/videos">
                <li>Videos</li>
              </Link>
              <Link className="mobile-nav-link" to="/images">
                <li>Gallery</li>
              </Link>

              <Link className="mobile-nav-link" to="/support">
                <li>Support</li>
              </Link>
              <Link className="mobile-nav-link" to="/about">
                <li>About</li>
              </Link>

              <hr />
              <li>
                <Link className="mobile-nav-link" to="/demo">
                  <button id="demo-btn">Schedule a Demo</button>
                </Link>
              </li>
              <li>
                <Link className="mobile-nav-link" to="/contact">
                  <button id="contact-btn">Contact</button>
                </Link>
              </li>
              <li>
                <a
                  // style={{ textDecoration: "none", color: "black" }}
                  href="http://portal.rpscorporation.com"
                  target="_blank"
                >
                  <button
                    style={{
                      background: "transparent",
                      padding: "10px",
                      border: "solid 2px black",
                      fontSize: "12px",
                      borderRadius: "5px",
                      margin: "5px",
                      width: "200px",
                    }}
                    id="mobile-login-btn"
                  >
                    Login
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </MobileNavStyles>
      </div>
    )
  }
}

export default MobileNav
