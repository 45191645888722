import styled from "styled-components"

export const MobileNavStyles = styled.div`
  /* position: relative; */
  background-color: whitesmoke;
  box-shadow: 8px 10px 8px #333;
  width: 85%;
  height: 80%;
  /* display: none; */
  /* z-index: 20; */

  ul {
    list-style-type: none;
    text-align: center;
    margin: 0;
    li {
      padding: 15px;
    }

    #contact-btn {
      background: transparent;
      padding: 10px;
      border: solid 2px black;
      font-size: normal;
      border-radius: 5px;
      margin: 5px;
      width: 200px;
    }

    #demo-btn {
      background: #f26522;
      padding: 10px;
      border: none;
      border-radius: 5px;
      font-size: normal;
      border-radius: 5px;
      margin: 5px;
      width: 200px;
    }
  }
`
