import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const MobileFooterStyles = styled.div`
  display: flex;
  position: fixed;
  height: 82px;
  /* left: 0; */
  bottom: 0;
  width: 100%;
  background-color: rgba(66, 66, 66, 0.9);
  text-align: center;
  display: none;
  z-index: 5;

  @media (max-width: 1280px) {
    display: block;
  }

  @media (max-width: 680px) {
    text-align: left;
  }

  button {
    border-radius: 5px;
    padding: 0.6rem;
    margin: 1rem;
    background-color: #f26522;
    border: none;
    color: white;
    font-size: 1.1em;

    &:hover {
      cursor: pointer;
    }

    @media (max-width: 500px) {
      width: 70px;
      font-size: 0.7em;
      padding: 0.5rem;
      margin-right: -5px;
    }
  }

  #phone-button {
    height: 2.6rem;
    /* margin-top: -150px; */
    /* text-align: center; */
    /* padding: 0; */
  }

  #demo-button {
  }

  .btn-container {
    display: flex;
    justify-content: center;

    @media (max-width: 680px) {
      justify-content: left;
    }
  }
`

const MobileFooter = () => {
  return (
    <MobileFooterStyles>
      <div className="btn-container">
        <Link to="/demo">
          <button id="demo-button">Schedule a Demo</button>
        </Link>
        <a href="tel:877-401-0730">
          <button id="phone-button">
            <i className="fas fa-phone"></i> Call Us
          </button>
        </a>
      </div>
    </MobileFooterStyles>
  )
}

export default MobileFooter
