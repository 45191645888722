import React, { Component } from "react"
import Header from "./header/Header"
import { GlobalStyles } from "./styles/GlobalStyles"
import { Helmet } from "react-helmet"
import Footer from "./footer/Footer"
import MobileFooter from "./footer/MobileFooter"
import ReactGA from "react-ga"

// ReactGA.initialize("UA-157503232-1")
// ReactGA.pageview(window.location.pathname + window.location.search)

class MainLayout extends Component {
  state = {
    supportSSeries: false,
    supportMSeries: false,
    supportLSeries: false,
    supportXLSeries: false,
  }

  toggleManuals = e => {
    if (e.target.value === "sSeries") {
      this.setState({
        supportSSeries: true,
        supportMSeries: false,
        supportLSeries: false,
        supportXLSeries: false,
      })
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.5.0/css/all.css"
            integrity="sha384-B4dIYHKNBt8Bc12p+WXckhzcICo0wtJAoU8YZTY5qE0Id1GSseTk6S+L3BlXeVIU"
            crossOrigin="anonymous"
          />
        </Helmet>
        <GlobalStyles>
          <Header />
          {this.props.children}

          <Footer />
          <MobileFooter />
        </GlobalStyles>
      </>
    )
  }
}

export default MainLayout
