import styled from "styled-components"

export const NavStyes = styled.div`
  #nav-container {
    color: white;
    height: 70px;
    display: grid;
    grid-template-columns: 25% 35% 25% 5% 10%;
    justify-items: center;
    align-items: center;
    z-index: 5;
    @media (max-width: 1280px) {
      grid-template-columns: 80% 20%;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: -ms-flexbox;
    }

    #company-logo {
      padding-left: 20px;

      @media (max-width: 1280px) {
        padding-left: 40%;
      }

      @media (max-width: 800px) {
        padding-left: 30%;
      }

      @media (max-width: 490px) {
        padding-left: 20px;
      }
    }

    #nav-link-container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-evenly;
      @media (max-width: 1280px) {
        display: none;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* margin-left: 150px; */
        display: -ms-flexbox;
      }
      #products-dropdown {
        background-color: whitesmoke;
        color: black;
        padding: 20px;
        position: absolute;
        top: 60px;
        border-radius: 5px;
        left: 38%;
        box-shadow: 5px 10px 5px #888888;
        ul {
          width: 100%;
          list-style-type: none;
          margin: 0;
          padding: 0;

          li {
            color: lightgray;
            width: 100%;
            padding: 10px;
            margin-bottom: 20px;

            &:hover {
              color: #333333;
            }
          }
        }
      }
    }

    .nav-links {
      color: #a7a9ac;
      margin: 0;
      padding: 0;
      text-decoration: none;
      &:hover {
        color: #f26522;
        cursor: pointer;
        transition-duration: 0.5s;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin: 0 1rem;
      }
    }

    .fa-angle-up {
      margin-left: 5px;
    }

    #nav-buttons {
      display: flex;
      width: 100%;
      justify-self: right;
      @media (max-width: 1280px) {
        display: none;
      }
      #contact-btn {
        background: transparent;
        padding: 10px;
        border: solid 2px black;
        font-size: 10px;
        border-radius: 5px;
        margin: 5px;
        &:hover {
          cursor: pointer;
          color: whitesmoke;
          border-color: whitesmoke;
          transition-duration: 0.5s;
        }
      }

      #demo-btn {
        background: #f26522;
        padding: 10px;
        /* border: none; */
        border-color: #f26522;
        border-style: solid;
        border-radius: 5px;
        font-size: 10px;
        border-radius: 5px;
        margin: 5px;

        &:hover {
          cursor: pointer;
          background: black;
          color: white;
          transition-duration: 0.5s;
        }
      }
    }
    #burger-menu {
      padding: 10px;
      justify-self: right;
      font-size: x-large;
      display: none;
      @media (max-width: 1280px) {
        display: block;
      }
    }
    /* #blank-responsive-div {
      @media (min-width: 1280px) {
        display: none;
      }
    } */
  }

  #phone-number {
    @media (max-width: 1280px) {
      display: none;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 500px;
    }
  }

  #mobile-nav {
    /* margin: 0; */
    position: relative;
    /* margin-top: -15px; */
    /* z-index: 60; */

    @media (min-width: 1280px) {
      display: none;
    }
  }

  #login {
    @media (max-width: 1280px) {
      display: none;
    }
  }
`
