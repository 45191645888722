import styled from "styled-components"

export const FooterStyles = styled.div`
  font-size: 14px;
  #footer-container {
    position: relative;
    background-color: #ccd2c6;
    height: 400px;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    justify-items: center;
    padding: 15px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: -ms-flexbox;
      /* width: 100%; */
      text-align: center;
      margin-right: auto;
      margin-left: auto;
    }

    /* bottom: 0; */

    .footer-elm {
      /* h2 {
        text-align: center;
      } */
      ul {
        list-style-type: none;
        padding: 0;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          width: 300px;
          text-align: center;
          margin-right: auto;
          margin-left: auto;
        }

        li {
          text-align: left;
          padding: 5px 0;
        }
      }
    }

    @media (max-width: 800px) {
      display: none;
    }

    a {
      text-decoration: none;
      color: black;
    }
  }

  #bear-image {
    position: absolute;
    top: 210px;
    left: 20px;
    img {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* height: 700px; */
        margin-top: 200px;
        margin-left: -1300px;
      }
    }
  }

  #footer-copy {
    position: relative;
    font-size: small;
    text-align: center;
    padding: 10px;
    /* width: 100%; */
    background-color: #606b55;

    @media (max-width: 1280px) {
      margin-bottom: 82px;
    }
  }

  #mobile-footer {
    display: none;
    text-align: center;
    font-size: 1.3em;
    background-color: #ccd2c6;
    height: 150px;
    padding: 10px;

    @media (max-width: 800px) {
      display: block;
    }
  }

  .mobile-social {
    display: flex;
    text-align: center;
    justify-content: center;

    a {
      margin: 0 0.5rem;
    }

    i {
      color: black;
      font-size: 20px;
    }
  }

  .product-section {
    display: flex;
    justify-items: space-between;
  }
`
